import React, { useState, useEffect } from 'react';
import { API_URL } from '../../config';
import './CuttingEdge.css';
import { useNavigate } from 'react-router-dom';

function LoadingSpinner() {
  return (
    <div className="water-ripple-loading"></div>
  );
}

function CuttingEdge() {
  const [articles, setArticles] = useState([]);
  const [rssArticles, setRssArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [isArticlesOpen, setIsArticlesOpen] = useState(true);
  const [isRssOpen, setIsRssOpen] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [rssOffset, setRssOffset] = useState(0);
  const [displayedRssArticles, setDisplayedRssArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArticles();
    fetchRssArticles();
  }, []); // 空依赖数组，确保只在组件挂载时执行一次

  const fetchArticles = async (isLoadingMore = false) => {
    if (isLoadingMore) {
      setIsLoadingMore(true);
    } else {
      setIsLoading(true);
    }
    try {
      const response = await fetch(`${API_URL}/api/cutting-edge?offset=${offset}`);
      if (!response.ok) {
        throw new Error(`HTTP 错误！状态: ${response.status}`);
      }
      const data = await response.json();
      setArticles(prevArticles => {
        const newArticles = [...prevArticles, ...data.message.items];
        const uniqueArticles = Array.from(new Set(newArticles.map(a => a.doi)))
          .map(doi => newArticles.find(a => a.doi === doi));
        return uniqueArticles.sort((a, b) => b.citationCount - a.citationCount);
      });
      setHasMore(data.message.items.length === 10);
      setOffset(prevOffset => prevOffset + data.message.items.length);
      setError(null);
    } catch (error) {
      console.error("获取最前沿文章时出错:", error);
      setError(error.message || "获取文章失败,请稍后再试。");
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const fetchRssArticles = async () => {
    try {
      const response = await fetch(`${API_URL}/api/cutting-edge/rss`);
      if (!response.ok) {
        throw new Error(`HTTP 错误！状态: ${response.status}`);
      }
      const data = await response.json();
      setRssArticles(data);
      setDisplayedRssArticles(data.slice(0, 10));
    } catch (error) {
      console.error("获取RSS文章时出错:", error);
      setRssArticles([]);
    }
  };

  const loadMoreArticles = () => {
    fetchArticles(true);
  };

  const loadMoreRssArticles = () => {
    const newOffset = rssOffset + 10;
    setDisplayedRssArticles(rssArticles.slice(0, newOffset));
    setRssOffset(newOffset);
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <div>加载失败: {error}</div>;
  if (articles.length === 0) return <div>暂无文章</div>;

  return (
    <div className="cutting-edge">
      <div className="ce-header">
        <h1 className="ce-title">人类最新科研<span className="ce-title-highlight">视野最前沿</span></h1>
        <div className="ce-translation-recommendation">
          <img src={require('../../assets/logos/ImmersiveTranslationLogo.png')} alt="沉浸式翻译" className="ce-translation-logo" />
          <div className="ce-translation-text">
            <p>推荐使用沉浸式翻译来翻译文章内容</p>
            <a href="https://immersivetranslate.com/" target="_blank" rel="noopener noreferrer" className="ce-translation-link">
              安装沉浸式翻译
            </a>
          </div>
        </div>
      </div>
      <button className="ce-back-button" onClick={() => navigate('/all-software')}>返回</button>
      <div className="ce-content">
        <div className="ce-collapsible">
          <button className="ce-collapsible-button" onClick={() => setIsArticlesOpen(!isArticlesOpen)}>
            论文 <span className="ce-collapsible-arrow">{isArticlesOpen ? '▼' : '▶'}</span>
          </button>
          {isArticlesOpen && (
            <>
              <div className="ce-grid">
                {articles.map((article, index) => (
                  <div key={index} className="ce-article-item">
                    <h2 className="ce-article-title">{article.title.replace(/<\/?SCP>/g, '')}</h2>
                    <p className="ce-article-journal">期刊: {article.journal}</p>
                    <p className="ce-article-category">分类: {article.type}</p>
                    <div className="ce-article-summary-container">
                      <p className="ce-article-summary">{article.abstract}</p>
                      <div className="ce-article-summary-gradient"></div>
                    </div>
                    <p className="ce-article-citations">引用次数: {article.citationCount}</p>
                    <a href={article.url} target="_blank" rel="noopener noreferrer" className="ce-article-link">阅读原文</a>
                    {article.doi && <p className="ce-article-doi">DOI: {article.doi}</p>}
                    <p className="ce-article-date">发布日期: {article.date}</p>
                  </div>
                ))}
              </div>
              {hasMore && (
                <button 
                  className="ce-load-more" 
                  onClick={loadMoreArticles}
                  disabled={isLoadingMore}
                >
                  {isLoadingMore ? '加载中...' : '显示更多'}
                </button>
              )}
            </>
          )}
        </div>
        <div className="ce-collapsible">
          <button className="ce-collapsible-button" onClick={() => setIsRssOpen(!isRssOpen)}>
            热门期刊 <span className="ce-collapsible-arrow">{isRssOpen ? '▼' : '▶'}</span>
          </button>
          {isRssOpen && (
            <>
              <div className="ce-grid">
                {displayedRssArticles.map((article, index) => (
                  <div key={index} className="ce-article-item">
                    <h2 className="ce-article-title">{article.title.replace(/<\/?SCP>/g, '')}</h2>
                    <p className="ce-article-journal">期刊: {article.journal}</p>
                    <div className="ce-article-summary-container">
                      <p className="ce-article-summary">{article.summary}</p>
                      <div className="ce-article-summary-gradient"></div>
                    </div>
                    <a href={article.url} target="_blank" rel="noopener noreferrer" className="ce-article-link">阅读原文</a>
                    <p className="ce-article-date">发布日期: {article.date}</p>
                  </div>
                ))}
              </div>
              {rssOffset < rssArticles.length && (
                <button className="ce-load-more" onClick={loadMoreRssArticles}>
                  显示更多
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CuttingEdge;

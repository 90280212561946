import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import ProductShowcase from './components/ProductShowcase/ProductShowcase';
import Footer from './components/Footer/Footer';
import AllSoftware from './pages/AllSoftware';
import BlogPage from './pages/Blog/BlogPage';

import './App.css';
import BlogAdmin from './components/BlogAdmin/BlogAdmin';
import BlogPost from './components/BlogPost/BlogPost';
import Login from './components/Login/Login';
import NotFound from './components/NotFound/NotFound';
import MarkdownViewer from './components/MarkdownViewer/MarkdownViewer';
import ProfitabilityAnalysis from './components/ProfitabilityAnalysis/ProfitabilityAnalysis';
import CuttingEdge from './components/CuttingEdge/CuttingEdge';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <AllSoftware />
              <main>
                <ProductShowcase />
              </main>
            </>
          } />
          <Route path="/all-software" element={<AllSoftware />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blog-admin" element={<BlogAdmin />} />
          <Route path="/docs/:filename" element={<MarkdownViewer />} />
          <Route path="/profitability-analysis" element={<ProfitabilityAnalysis />} />
          <Route path="/cutting-edge" element={<CuttingEdge />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
